import React from "react"

import {Link} from "gatsby"

import {MotivationalStyles} from "../styles/overlay-styles"
import {styled} from "@linaria/react"

interface Props {
  visible: boolean
  isLandscape: boolean
}

const GetInTouch = (props: Props): JSX.Element => {
  return (
    <GetInTouchStyles transparent={!props.visible}>
      <MotivationalStyles
        visible={props.visible}
        controls={false}
        isLandscape={props.isLandscape}
      >
        <h1>Everything starts with a hello</h1>
        <Link to="/contact/">Get in touch</Link>
      </MotivationalStyles>
    </GetInTouchStyles>
  )
}

export default GetInTouch

const GetInTouchStyles = styled.div<{transparent: boolean}>`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: backdrop-filter 0.5s ease-in-out,
    background-color 0.5s ease-in-out, top 0.5s ease-in-out;

  background-color: ${props =>
    props.transparent ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.3)"};
`
