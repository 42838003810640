import React, {useState, useEffect} from "react"
import {useRecoilValue} from "recoil"
import {mouseMovedState, videoTimeState, watchedStoriesState} from "../atoms"
import {
  MuteButton,
  StoryInfo,
  StoryMenu,
  Subtitles,
  VideoControls,
  GetInTouch,
} from "."

import {useOrientation} from "../hooks/orientation"

import {Story} from "../types"

import Theme from "../styles/theme"
import {
  OverlayStyles,
  CallToActionStyles,
  StoryMenuWrapperStyles,
  BlurredOverlayStyles,
  BlurredBackdropStyles,
  // BlurredOverlayStyles,
} from "../styles/overlay-styles"
import {styled} from "@linaria/react"

interface Props {
  currentStory?: Story
  stories: Story[]
}

const StoryOverlay = (props: Props): JSX.Element => {
  const currentTime = useRecoilValue(videoTimeState)
  const watched = useRecoilValue(watchedStoriesState)
  const [storyMenuOpen, setStoryMenuOpen] = useState<boolean>(false)
  const mouseMoved = useRecoilValue(mouseMovedState)
  const [dynamicallyShowingInfo, setDynamicallyShowingInfo] = useState<boolean>(
    true,
  )
  const [manuallyShowingInfo, setManuallyShowingInfo] = useState<boolean>(false)

  const orientation = useOrientation()
  const [isLandscape, setIsLandscape] = useState<boolean>(true)

  useEffect(() => {
    setIsLandscape(orientation === "landscape" ? true : false)
  }, [orientation])

  useEffect(() => {
    // Potentially show the title of the current story
    setDynamicallyShowingInfo(currentTime < 5)
  }, [currentTime])

  const showingInfo = dynamicallyShowingInfo || manuallyShowingInfo
  const desktopShowingInfo = dynamicallyShowingInfo || mouseMoved

  const allStoriesWatched = watched.length === props.stories.length

  return (
    <OverlayStyles
      className={`${BlurredOverlayStyles} ${
        !storyMenuOpen && BlurredBackdropStyles
      }`}
      onClick={e => {
        if (e.target === e.currentTarget) {
          setManuallyShowingInfo(!manuallyShowingInfo)
        }
      }}
    >
      <LinearGradientStyles
        transparent={showingInfo && !storyMenuOpen}
        desktopTransparent={desktopShowingInfo && !storyMenuOpen}
      />
      <MuteButton />
      <VideoControls
        active={manuallyShowingInfo && !storyMenuOpen}
        storyMenuOpen={storyMenuOpen}
        watched={
          !!watched.find(story => story.slug === props?.currentStory?.slug)
        }
        setInactive={() => setManuallyShowingInfo(false)}
      />

      <CallToActionStyles>
        <StoryInfo
          story={props.currentStory}
          desktopVisible={
            desktopShowingInfo && !storyMenuOpen && !allStoriesWatched
          }
          visible={showingInfo && !storyMenuOpen && !allStoriesWatched}
          highlighted={currentTime < 6}
        />

        <StoryMenuWrapperStyles visible>
          <StoryMenu
            open={storyMenuOpen}
            setOpen={setStoryMenuOpen}
            isLandscape={isLandscape}
            {...props}
          />
        </StoryMenuWrapperStyles>

        <Subtitles
          storyMenuOpen={storyMenuOpen}
          storyInfoShowing={showingInfo && !storyMenuOpen}
          isLandscape={isLandscape}
        />
      </CallToActionStyles>
      <GetInTouch
        visible={!storyMenuOpen && allStoriesWatched}
        isLandscape={isLandscape}
      />
    </OverlayStyles>
  )
}

export default StoryOverlay

// It's not possible to transition a linear-gradient, so we put it
// in its own div and transition its opacity instead
const LinearGradientStyles = styled.div<{
  transparent: boolean
  desktopTransparent: boolean
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 58.7%,
    rgba(0, 0, 0, 0.4) 100%
  );

  @media ${Theme.breakpoints.notDesktop} {
    opacity: ${props => (props.transparent ? "0" : "1")};
  }

  @media ${Theme.breakpoints.desktop} {
    opacity: ${props => (props.transparent ? "0" : "1")};
  }
`
