import React, {useMemo, useEffect, useState} from "react"
import {navigate} from "gatsby"
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil"
import Hls from "hls.js"
import {
  videoTimeState,
  videoRefState,
  watchedStoriesState,
  homepageWatchedState,
  videoMutedState,
  subtitlesState,
  currentVideoState,
  currentStoryState,
  videoPausedState,
} from "../atoms"

import {useOrientation} from "../hooks/orientation"
import {isCompatible} from "../hooks/compatibility"
import {styled} from "@linaria/react"

const FullscreenVideo = (): JSX.Element | null => {
  const muted = useRecoilValue(videoMutedState)
  const paused = useRecoilValue(videoPausedState)
  const [videoRef, setVideoRef] = useRecoilState(videoRefState)
  const [watched, setWatched] = useRecoilState(watchedStoriesState)
  const setSubtitles = useSetRecoilState(subtitlesState)
  const setHomepageWatched = useSetRecoilState(homepageWatchedState)
  const [videoTime, setVideoTime] = useRecoilState(videoTimeState)
  const currentVideo = useRecoilValue(currentVideoState)
  const currentStory = useRecoilValue(currentStoryState)
  const [currentHlsInstance, setCurrentHlsInstance] = useState<Hls>()

  function loadSubtitles() {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", () => {
        showSubtitles()
      })
    }
  }

  function showSubtitles() {
    const track = videoRef.current?.textTracks[0]
    if (track) {
      track.mode = "hidden"
      track.oncuechange = (event: any) => {
        setSubtitles(event.currentTarget?.activeCues[0]?.text.split("\n"))
      }
    }
  }

  if (isCompatible()) {
    const orientation = useOrientation()

    useEffect(() => {
      if (videoRef.current && currentVideo) {
        const startTime = parseInt(window.location.hash.split("t=")[1]) || 0
        window.location.hash = ""
        const startPosition =
          videoTime === 0
            ? startTime
            : videoRef.current?.currentTime || startTime

        const url = `${currentVideo[orientation]}#t=${startPosition}`

        if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
          // If HLS is natively supported, let the browser do the work
          videoRef.current.src = url
          videoRef.current.addEventListener("loadedmetadata", () => {
            showSubtitles()
            paused ? videoRef.current?.pause() : videoRef.current?.play()
          })
        } else if (Hls.isSupported()) {
          // If the browser supports MSE, use hls.js to play the video
          const hls = new Hls({
            xhrSetup: xhr => {
              xhr.withCredentials = true
            },
            startPosition: startPosition,
          })

          // First, unload a previously initialised HLS instance (if present)
          if (currentHlsInstance) {
            currentHlsInstance.stopLoad()
            currentHlsInstance.detachMedia()
            currentHlsInstance.destroy()
          }

          // Then, initialise the new HLS instance
          hls.loadSource(url)
          hls.attachMedia(videoRef.current)
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            loadSubtitles()
            paused ? videoRef.current?.pause() : videoRef.current?.play()
          })
          setCurrentHlsInstance(hls)
        } else {
          navigate(`/incompatible/`)
          return
        }

        const interval = setInterval(() => {
          if (videoRef.current && videoRef.current.currentTime > 0)
            setVideoTime(videoRef.current.currentTime)
        }, 500)
        return () => clearInterval(interval)
      }
    }, [videoRef])

    return useMemo(() => {
      if (currentVideo) {
        return (
          <VideoStyles
            ref={current => setVideoRef({current, muted: true})}
            muted={muted}
            crossOrigin="anonymous"
            controls={false}
            autoPlay={true}
            playsInline={true}
            poster={
              videoRef.current?.currentTime === 0
                ? orientation === "portrait"
                  ? currentVideo.portraitImage?.url
                  : currentVideo.landscapeImage?.url
                : undefined
            }
            loop={false}
            onEnded={() => {
              if (
                currentStory &&
                !watched.filter(story => story.slug === currentStory?.slug)
                  .length
              ) {
                setWatched(watched.concat([currentStory]))
              } else {
                setHomepageWatched(true)
              }
            }}
          >
            <track
              label="English"
              kind="metadata"
              key={orientation}
              srcLang="en"
              default
              src={currentVideo?.subtitles.url}
            />
          </VideoStyles>
        )
      } else {
        return null
      }
    }, [orientation, currentVideo])
  } else {
    return null
  }
}

export default FullscreenVideo

const VideoStyles = styled.video`
  position: fixed;
  background: transparent;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  &::cue {
    font-size: 1.8rem;
  }
`
