import React from "react"
import {styled} from "@linaria/react"

interface Props {
  seconds: number
}

const CountdownTimer = (props: Props): JSX.Element => {
  return (
    <CountdownTimerStyles>
      <div>
        <NumberStyles seconds={props.seconds}>
          {[...Array(props.seconds + 1)].map((_, i) => (
            <span key={i}>{props.seconds - i}</span>
          ))}
        </NumberStyles>
        <CircleStyles seconds={props.seconds}>
          <svg>
            <circle r="27" cx="30" cy="30" />
            <circle r="27" cx="30" cy="30" />
          </svg>
        </CircleStyles>
        <BlurStyles />
      </div>
    </CountdownTimerStyles>
  )
}

export default CountdownTimer

const CountdownTimerStyles = styled.div`
  position: absolute;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
  }
`

const CircleStyles = styled.div<{seconds: number}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;

  svg {
    width: 6rem;
    height: 6rem;
    transform: rotateY(-180deg) rotateZ(-90deg);

    > circle:first-of-type {
      stroke-dasharray: 169.5px;
      stroke-dashoffset: -169.5px;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke: white;
      fill: none;
      animation: ${`countdown ${(props: {seconds: number}) =>
        props.seconds}s linear 1 forwards`};
    }

    > circle:last-of-type {
      stroke-dasharray: 169.5px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke: rgba(255, 255, 255, 0.2);
      fill: none;
    }
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: -169.5px;
    }
    to {
      stroke-dashoffset: 0px;
    }
  }
`

const NumberStyles = styled.div<{seconds: number}>`
  display: inline-block;
  height: 3rem;
  width: 3rem;
  overflow: hidden;
  text-align: center;
  z-index: 1;

  > span {
    display: block;
    height: 3rem;
    margin: 0 auto;
    color: #fff;
    font-size: 3rem;
    animation: ${`seconds ${(props: {seconds: number}) =>
      props.seconds}s step-end forwards`};
  }

  @keyframes seconds {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-3rem);
    }
    40% {
      transform: translateY(-6rem);
    }
    60% {
      transform: translateY(-9rem);
    }
    80% {
      transform: translateY(-12rem);
    }
    100% {
      transform: translateY(-15rem);
    }
  }
`

const BlurStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 1rem;
  backdrop-filter: blur(3px);
`
