import React, {useEffect, useState} from "react"
import {css} from "@linaria/core"
import {Video} from "../types"
import {useRecoilValue} from "recoil"
import {videoTimeState} from "../atoms"
import MoonLoader from "react-spinners/MoonLoader"
import {styled} from "@linaria/react"

const FullscreenPoster = (props: {videoSource?: Video}): JSX.Element | null => {
  const videoTime = useRecoilValue(videoTimeState)
  const [loaderVisible, setLoaderVisible] = useState<boolean>(false)

  useEffect(() => {
    // Fade the loader in with a timeout so it's never visible on fast browsers
    setTimeout(() => {
      setLoaderVisible(videoTime === 0)
    }, 200)
  }, [videoTime])

  const loader = (
    <LoaderStyles visible={loaderVisible}>
      <MoonLoader
        loading={true}
        color="white"
        size={100}
        speedMultiplier={0.5}
      />
    </LoaderStyles>
  )

  if (!props.videoSource || videoTime > 0) {
    return <figure className={PosterStyles}>{loader}</figure>
  } else {
    return (
      <figure className={PosterStyles}>
        <img className="portrait" src={props.videoSource.portraitImage.url} />
        <img className="landscape" src={props.videoSource.landscapeImage.url} />
        {loader}
      </figure>
    )
  }
}

export default FullscreenPoster

const PosterStyles = css`
  position: fixed;
  margin: 0;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (orientation: landscape) {
      &.landscape {
        display: block;
      }
    }

    @media (orientation: portrait) {
      &.portrait {
        display: block;
      }
    }
  }
`

const LoaderStyles = styled.div<{visible: boolean}>`
  position: absolute;
  top: 35%;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 1s opacity ease-in-out;
`
