import {css} from "@linaria/core"
import Theme from "../styles/theme"
import {styled} from "@linaria/react"

export const OverlayStyles = styled.div`
  position: fixed;
  padding-top: 6.7rem;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${Theme.breakpoints.desktop} {
    padding: 3rem 5.5rem;
  }
`

export const CallToActionStyles = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  @media ${Theme.breakpoints.notDesktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
  }

  @media ${Theme.breakpoints.desktop} {
    top: 0;
    z-index: 0;
  }
`

export const DynamicCallToActionStyles = css`
  position: relative;
  height: 10.5rem;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: margin-top 0.5s ease-in-out;

  @media ${Theme.breakpoints.desktop} {
    height: 0;
  }
`

export const MotivationalStyles = styled.div<{
  visible: boolean
  controls: boolean
  isLandscape: boolean
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: ${props => (props.isLandscape ? "28%" : "35%")};
  left: 0;
  right: 0;
  color: #fff;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  transition: 0.5s opacity ease-in-out;
  pointer-events: none;
  user-select: none;

  @media ${Theme.breakpoints.smallDown} {
    top: ${props => (props.isLandscape ? "24%" : "33%")};
  }

  h1 {
    max-width: 24rem;
    text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 2.6rem;

    /* Resize for iPad portrait mode */
    @media ${Theme.breakpoints.small} {
      max-width: ${props => (props.isLandscape ? "" : "50rem")};
      line-height: ${props => (props.isLandscape ? "" : "6rem")};
      font-size: ${props => (props.isLandscape ? "3rem" : "5rem")};
    }
  }

  a {
    padding: 1rem 9rem;
    text-decoration: none;
    box-shadow: 0 0 0px 7px rgb(151 151 151 / 10%);
    color: white;
    font-size: 1.2rem;
    margin: ${props => (props.isLandscape ? "" : "2.2rem")};
    pointer-events: ${props => (props.visible ? "all" : "none")};
    background-color: rgb(255 255 255 / 10%);
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover {
      background-color: rgb(255 255 255 / 20%);
    }

    /* Resize for iPad portrait mode */
    @media ${Theme.breakpoints.small} {
      font-size: ${props => (props.isLandscape ? "" : "1.8rem")};
    }
  }

  @media ${Theme.breakpoints.desktop} {
    max-width: 112rem;
    margin: 0 auto;
    opacity: ${props => (props.visible && !props.controls ? "1" : "0")};

    h1 {
      max-width: 60rem;
      line-height: 8rem;
      font-size: 7rem;
    }

    a {
      width: 36rem;
      height: 5.8rem;
      align-items: center;
      padding: 1rem;
      margin: 2.2rem;
      line-height: 3.7rem;
      font-size: 1.8rem;
      box-shadow: 0 0 0px 7px rgb(151 151 151 / 10%);
    }
  }

  @media ${Theme.breakpoints.notDesktop} {
    opacity: ${props => (props.visible && !props.controls ? "1" : "0")};
  }

  /* Center vertically on smaller mobile landscape */
  @media ${Theme.breakpoints.smallDown} {
  }
`

export const StoryMenuWrapperStyles = styled.div<{visible: boolean}>`
  opacity: ${props => (props.visible ? "1" : "0")};
  pointer-events: ${props => (props.visible ? "all" : "none")};
  transition: opacity 0.5s ease-in-out;
`

export const PulseStyles = css`
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    }
    70% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  border-radius: 50%;
  transition: 0.5s opacity ease-in-out;
`

export const PulseStylesAnimation = css`
  animation: pulse 1s 1 ease-out;
`

export const BlurredOverlayStyles = css`
  @media ${Theme.breakpoints.notDesktop} {
    transition: backdrop-filter 0.5s ease-in-out,
      background-color 0.5s ease-in-out, top 0.5s ease-in-out;

    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
  }
`

export const BlurredBackdropStyles = css`
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
`
