import {css} from "@linaria/core"
import React from "react"
import theme from "../../styles/theme"
import PageWidth from "../page-width"

const Footer = (): JSX.Element => {
  const footerColumns = [
    {
      title: "Voormedia",
      items: [
        {text: "Homepage", url: "/"},
        {text: "About us", url: "/about/"},
        {text: "Contact", url: "/contact/"},
      ],
    },
    {
      title: "Call",
      items: [{text: "+31 20 7155310", url: "tel:+31 20 7155310"}],
    },
    {
      title: "Write",
      items: [{text: "info@voormedia.com", url: "mailto: info@voormedia.com"}],
    },
    {
      title: "Visit",
      items: [
        {
          text: "Meeuwenlaan 100 <br/>1021 JL Amsterdam",
          url: "https://duckduckgo.com/?q=voormedia&va=b&t=hc&ia=web&iaxm=maps",
        },
      ],
    },
  ]
  return (
    <PageWidth>
      <div className={FooterStyles}>
        {footerColumns.map((column, index) => {
          return (
            <div className="column" key={index}>
              <p className="title">{column.title}</p>
              {column.items.map((item, index) => {
                return (
                  <div key={index}>
                    <a
                      className={item.url == "" ? "no-link" : "link"}
                      title={item.text}
                      dangerouslySetInnerHTML={{__html: item.text}}
                      href={item.url == "" ? "#" : item.url}
                    ></a>
                  </div>
                )
              })}
            </div>
          )
        })}
        <p className="copyright">© {new Date().getFullYear()} Voormedia</p>
      </div>
    </PageWidth>
  )
}
export default Footer

const FooterStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2rem;
  padding: 10rem 0;
  background-color: ${theme.colors.backgrounds.darkGrey};
  color: ${theme.colors.fontcolors.hero};
  @media ${theme.breakpoints.largeDesktopDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${theme.breakpoints.mediumDown} {
    height: fit-content;
    padding: 2rem 0 8rem;
  }

  @media ${theme.breakpoints.mediumDown} {
    grid-template-columns: 1fr;
  }

  p.copyright {
    margin-top: 6rem;
    font-size: 2rem;

    @media ${theme.breakpoints.largeDesktopDown} {
      font-size: 1.6rem;
    }
  }

  .column {
    p.title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 2rem;
      @media ${theme.breakpoints.largeDesktopDown} {
        margin-top: 4rem;
      }
    }

    a {
      cursor: pointer;
      color: ${theme.colors.fontcolors.hero};
      text-decoration: none;
      font-size: 2rem;
      line-height: 3.5rem;
      font-weight: 200;

      &.no-link {
        cursor: auto;
      }
      @media ${theme.breakpoints.largeDesktopDown} {
        font-size: 1.6rem;
      }
    }
  }
`
