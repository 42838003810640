import React, {useState, useEffect} from "react"
import {css} from "@linaria/core"
import {useRecoilValue} from "recoil"
import {homepageWatchedState, videoTimeState} from "../atoms"
import {MuteButton, StoryCta, StoryMenu, Subtitles, VideoControls} from "."
import {Story} from "../types"

// react-device-detect doesn't support the resize event, so we're hooking to it manually
import {useWindowSize} from "../hooks/screensize"
import {useOrientation} from "../hooks/orientation"

import {
  OverlayStyles,
  CallToActionStyles,
  DynamicCallToActionStyles,
  StoryMenuWrapperStyles,
  MotivationalStyles,
  BlurredOverlayStyles,
  BlurredBackdropStyles,
} from "../styles/overlay-styles"

import Theme from "../styles/theme"
import {styled} from "@linaria/react"

interface Props {
  stories: Story[]
}

const HomepageOverlay = (props: Props): JSX.Element => {
  const currentTime = useRecoilValue(videoTimeState)
  const homepageWatched = useRecoilValue(homepageWatchedState)
  const [showingInfo, setShowingInfo] = useState<boolean>(false)
  const [showingControls, setShowingControls] = useState<boolean>(false)
  const [storyMenuOpen, setStoryMenuOpen] = useState<boolean>(false)
  const [currentCta, setCurrentCta] = useState<Story | null>(null)
  const [previousCta, setPreviousCta] = useState<Story | null>(null)
  const showCtasFrom = Math.min(
    ...props.stories
      .map(story => story.indexHighlights.map(highlight => highlight.from))
      .flat(),
  )
  const windowSize = useWindowSize()
  const [isDesktop, setIsDesktop] = useState<boolean>(true)
  const orientation = useOrientation()
  const [isLandscape, setIsLandscape] = useState<boolean>(false)

  useEffect(() => {
    if (windowSize) {
      setIsDesktop(windowSize > 960 ? true : false)
    }
    setIsLandscape(orientation === "landscape" ? true : false)
  }, [windowSize, orientation])

  useEffect(() => {
    let ctaToShow: Story | null = null
    // Show links to stories on the homepage
    props.stories.forEach(story => {
      story.indexHighlights.forEach(highlight => {
        if (highlight.from < currentTime && highlight.to > currentTime) {
          ctaToShow = story
        }
      })
    })
    if (currentTime < showCtasFrom) setCurrentCta(null)
    if (ctaToShow) {
      if (currentCta !== ctaToShow) {
        setPreviousCta(currentCta)
      }
      setCurrentCta(ctaToShow)
    }
    setShowingInfo(showCtasFrom < currentTime)
  }, [currentTime])

  return (
    <OverlayStyles
      onClick={e => {
        if (e.target === e.currentTarget) {
          setShowingControls(!showingControls)
        }
      }}
    >
      <MuteButton />
      <VideoControls
        active={!storyMenuOpen && showingControls}
        storyMenuOpen={storyMenuOpen}
        watched={homepageWatched}
        setInactive={() => setShowingControls(false)}
      />

      <MotivationalStyles
        visible={currentTime > 0 && !currentCta}
        controls={showingControls}
        isLandscape={isLandscape}
      >
        <h1>Taking your business to the next level</h1>
      </MotivationalStyles>

      <CallToActionStyles
        className={`${BlurredOverlayStyles} ${
          (!showingInfo && !storyMenuOpen) ||
          (!storyMenuOpen && !isDesktop && isLandscape)
            ? BlurredBackdropStyles
            : ""
        }`}
      >
        <div className={DynamicCallToActionStyles}>
          {props.stories.map(story => (
            <CtaWrapperStyles
              className={`
              ${
                story === currentCta && isDesktop
                  ? CtaWideWrapperCurrentStyles
                  : ""
              }
              ${
                story === previousCta && isDesktop
                  ? CtaWideWrapperPreviousStyles
                  : ""
              }
              ${
                story === currentCta && !isDesktop && isLandscape
                  ? MobileLandscapeCtaCurrentWrapperStyles
                  : ""
              }
              ${
                story === previousCta && !isDesktop && isLandscape
                  ? MobileLandscapeCtaPreviousWrapperStyles
                  : ""
              }
              `}
              current={story === currentCta}
              previous={story === previousCta}
              hidden={storyMenuOpen}
            >
              <StoryCta story={story} isLandscape={isLandscape} />
            </CtaWrapperStyles>
          ))}
        </div>
        <div className="footer">
          <MuteButton />
          <StoryMenuWrapperStyles visible>
            <StoryMenu
              open={storyMenuOpen}
              setOpen={setStoryMenuOpen}
              isLandscape={isLandscape}
              {...props}
            />
          </StoryMenuWrapperStyles>
          <Subtitles
            isHomepage={true}
            storyMenuOpen={storyMenuOpen}
            showingCta={true}
            isLandscape={isLandscape}
          />
        </div>
      </CallToActionStyles>
    </OverlayStyles>
  )
}

export default HomepageOverlay

const CtaWrapperStyles = styled.div<{
  current: boolean
  previous: boolean
  hidden: boolean
}>`
  position: absolute;
  top: 0.7rem;
  bottom: 0;
  left: ${props =>
    props.current ? "0.5rem" : props.previous ? "-100vw" : "100vw"};
  right: ${props =>
    props.current ? "0" : props.previous ? "100vw" : "-100vw"};
  opacity: ${props => (props.current && !props.hidden ? "1" : "0")};
  transition: opacity 0.5s ease-in-out, left 0.8s ease-in-out,
    right 0.8s ease-in-out;

  transition-delay: ${props => (props.hidden ? "0" : "0.3s")};
`

const CtaWideWrapperCurrentStyles = css`
  left: calc(100vw - 40.2rem);
  right: 3rem;
  > a {
    bottom: calc(-100vh + 40rem);
    padding: 2rem;
    backdrop-filter: blur(8px);
    border-radius: 1rem;
  }
`

const CtaWideWrapperPreviousStyles = css`
  left: -100vw;
  right: 100vw;
  > a {
    bottom: calc(-100vh + 40rem);
    padding: 2rem;
    backdrop-filter: blur(8px);
    border-radius: 1rem;
  }
`

const MobileLandscapeCtaCurrentWrapperStyles = css`
  left: calc(100vw - 25.2rem);
  > a {
    bottom: 5rem;
    padding: 1rem;
    width: 25rem;
    backdrop-filter: blur(8px);

    @media ${Theme.breakpoints.smallDown} {
      bottom: 3.6rem;
    }
  }
`
const MobileLandscapeCtaPreviousWrapperStyles = css`
  left: -100vw;
  > a {
    bottom: 5rem;
    padding: 1rem;
    width: 25rem;
    backdrop-filter: blur(8px);

    @media ${Theme.breakpoints.smallDown} {
      bottom: 3.6rem;
    }
  }
`
