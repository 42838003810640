import React from "react"
import {Layout, FullscreenPoster} from "."
import {graphql, useStaticQuery} from "gatsby"
import {Story, Video, Seo} from "../types"

// Load only client side
import loadable from "@loadable/component"
import {isNotWideoPage} from "../hooks/page-type"
const FullscreenVideo = loadable(() => import("./fullscreen-video"))

export interface DatoContent {
  path: string
  "*"?: string
  location?: {pathname: string}
  pageResources: {
    staticQueryResults: SingleResult[]
  }
  pageContext: Story
}

interface SingleResult {
  data: {
    datoCmsHomepage?: {
      video: Video
      seo: Seo
    }
    allDatoCmsStory?: {
      edges: {
        node: Story
      }[]
    }
  }
}

const Wrapper = (
  props: React.PropsWithChildren<DatoContent>,
): JSX.Element | null => {
  const {
    datoCmsHomepage: {video, seo},
  } = useStaticQuery(graphql`
    query HomepagePosterQuery {
      datoCmsHomepage {
        video {
          portraitImage {
            url
          }
          landscapeImage {
            url
          }
        }
        seo {
          title
          description
          image {
            url
          }
        }
      }
    }
  `)

  // This is used to show a first frame image and SEO data in Server Side Rendering
  // prior to the actual video being set in the state... Feels like fighting
  // against Gatsby, not sure if there's a better solution?
  const retrieveContent = () => {
    // When building the pageContext property is available to use
    if (typeof window === "undefined") {
      // Homepage posters are retrieved through a static query, there is no context for it
      // since it isn't generated through createPage
      if (props["*"] === "") {
        return {video: video, seo: seo}
      } else {
        return {video: props.pageContext.video, seo: props.pageContext.seo}
      }
    } else if (!props.pageResources) {
      return undefined
    }

    if (props.path === "/") {
      const content = Object.values(
        props.pageResources.staticQueryResults,
      ).filter(q => !!q.data.datoCmsHomepage)[0]?.data?.datoCmsHomepage

      return {video: content?.video, seo: content?.seo}
    }

    const content = Object.values(props.pageResources.staticQueryResults)
      .filter(q => !!q.data.allDatoCmsStory)[0]
      ?.data?.allDatoCmsStory?.edges.find(story =>
        props.path.includes(story.node.slug),
      )?.node

    return {video: content?.video, seo: content?.seo}
  }

  const path = props.location?.pathname || props.path || ""
  const activeVideo: Video | undefined = retrieveContent()?.video
  return (
    <Layout path={path} seoSource={retrieveContent()?.seo}>
      {!isNotWideoPage(path) && (
        <FullscreenPoster
          key={activeVideo?.landscape || "none"}
          videoSource={activeVideo}
        />
      )}
      {/* NOTE: The video is rendered at the highest level to prevent unmounting when switching
            to another video. iOS Safari will not autoplay the video when unmuted otherwise. */}
      {!isNotWideoPage(path) && <FullscreenVideo />}
      {props.children}
    </Layout>
  )
}

export default Wrapper
