const Theme = {
  fonts: {
    body: "Poppins",
  },
  colors: {
    backgrounds: {
      orange: "#FA6400",
      darkGrey: "#292929",
      lightGrey: "#F9F9F9",
      white: "#FFFFFF",
      red: "#C11E43",
    },
    borders: {
      gray: "#a4a4a4",
    },
    buttoncolors: {
      light: "#FFFFFF",
      grayBorder: "#f5f5f5",
    },
    fontcolors: {
      body: "#000000",
      hero: "#FFFFFF",
      secondary: "#C11E43",
      gray: "#A4A4A4",
    },
  },
  breakpoints: {
    smallMobileDown: "(max-width: 350px)",
    mobile: "(min-width: 480px)",
    mobileDown: "(max-width: 480px)",
    iPhone5LandscapeDown: "(max-width: 570px)",
    smallDown: "(max-width: 640px)",
    small: "(min-width: 640px)",
    medium: "(min-width: 800px)",
    mediumDown: "(max-width: 800px)",
    desktopDown: "max-width: 960px",
    desktop: "(min-width: 960px)",
    notDesktop: "(max-width: 960px)",
    largeDesktop: "(min-width: 1235px)",
    largeDesktopDown: "(max-width: 1235px)",
    superlargedesktop: "(min-width: 1730px)",
  },
  layout: {
    large: "144rem",
  },
  pagewidth: {
    desktop: "10rem",
    desktopdown: "2rem",
    mobile: "1rem",
    largescreen: "15rem",
    superlargedesktop: "40rem",
  },
}

export default Theme
