/* eslint-disable */
import Wrapper from "./src/components/wrapper"
import {RecoilRoot} from "recoil"
import {ErrorBoundary} from 'react-error-boundary'

const handleError = () => {
  // TODO: Redirecting to the incompatibility page in old browsers based
  // on the capability to play the HLS videos is tricky because they often
  // can't run the React app itself either. Currently we are very aggressive,
  // assuming any runtime error warrants a redirect to the incompatibility page.
  // Ideally we improve on this in the future.
  // Nov 2023, added override for about us page to prevent breaking on browsers other than chrome
  // will also need fixing in the future
  if (process.env.NODE_ENV === 'production') {
    if (window.location.pathname !== '/about/')
    window.location = '/incompatible/'
  }
}

export const wrapPageElement = ({element, props}) => (
  <ErrorBoundary onError={handleError} fallback={<div/>}>
    <RecoilRoot>
      <Wrapper {...props}>{element}</Wrapper>
    </RecoilRoot>
  </ErrorBoundary>
)
