import React from "react"
import {css} from "@linaria/core"
import {Link} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRecoilValue, useRecoilState, useSetRecoilState} from "recoil"
import {
  videoTimeState,
  watchedStoriesState,
  currentStoryState,
  currentVideoState,
  homepageWatchedState,
} from "../atoms"
import Theme from "../styles/theme"

import {CountdownTimer} from "."

import {Story} from "../types"
import {faRedo} from "@fortawesome/pro-light-svg-icons"
import {faAngleRight, faStopwatch} from "@fortawesome/pro-regular-svg-icons"
import {styled} from "@linaria/react"

interface Props {
  story: Story
  watchingNow?: boolean
  playingNext?: boolean
  hidden?: boolean
  isLandscape: boolean
}

const StoryCta = (props: Props): JSX.Element => {
  const [currentTime, setCurrentTime] = useRecoilState(videoTimeState)
  const setCurrentVideo = useSetRecoilState(currentVideoState)
  const [watchedStories, setWatchedStories] = useRecoilState(
    watchedStoriesState,
  )
  const currentStory = useRecoilValue(currentStoryState)
  const watched = watchedStories.filter(
    (story: Story | null) => story?.slug === props.story.slug,
  ).length
  const finished = Math.round(currentTime) >= props.story.video.duration

  const setHomepageWatched = useSetRecoilState(homepageWatchedState)

  const duration = (time: number) => {
    return (
      <span className="time">
        {`${Math.floor(time / 60)}:${("0" + (time % 60)).slice(-2)}`}
      </span>
    )
  }

  return (
    <StoryCtaStyles
      watched={!!watched && !props.watchingNow}
      hidden={!!props.hidden}
      watchingNow={!!props.watchingNow}
      isLandscape={props.isLandscape}
      to={`/stories/${props.story.slug}/`}
      onClick={() => {
        setHomepageWatched(false)
        setWatchedStories(
          watchedStories.filter(story => story.slug !== props.story.slug),
        )
        if (currentStory?.slug === props.story.slug) {
          setCurrentTime(0)
          setCurrentVideo({...props.story.video})
        }
      }}
    >
      <figure
        style={{
          backgroundImage: `url(${props.story.thumbnail.url}`,
        }}
      >
        {(props.playingNext && <CountdownTimer seconds={5} />) ||
          (!!watched && (
            <div className={RewatchIconStyles}>
              <FontAwesomeIcon icon={faRedo} />
            </div>
          ))}
      </figure>
      <div className={CtaInfoStyles}>
        <div className={AngleIconStyles}>
          <span className="status">
            {props.playingNext
              ? "playing next"
              : props.watchingNow
              ? finished
                ? "watched"
                : "watching"
              : watched
              ? "watched"
              : "watch now"}
          </span>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <h3>{props.story.title}</h3>
        <span className="subject">{props.story.subjectPerson}</span>
        <span className="subject">{props.story.subjectProject}</span>
        <span className="duration">
          <FontAwesomeIcon icon={faStopwatch} />{" "}
          {props.watchingNow && (
            <>
              {duration(Math.floor(currentTime))}
              {" / "}
            </>
          )}
          {!props.watchingNow && !!watched && (
            <>
              {duration(props.story.video.duration)}
              {" / "}
            </>
          )}
          {duration(props.story.video.duration)}
        </span>
      </div>
    </StoryCtaStyles>
  )
}

export default StoryCta

const StoryCtaStyles = styled(Link)<{
  watched: boolean
  hidden: boolean
  watchingNow: boolean
  isLandscape: boolean
}>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.9rem 2.5rem;
  pointer-events: ${props => (props.hidden ? "none" : "all")};
  filter: ${props => (props.watched ? "grayscale(1)" : "none")};
  opacity: ${props => (props.watched ? "0.8" : "1")};

  figure {
    position: relative;
    margin: 0 2rem 0 0;
    border-radius: 1rem;
    width: 8.5rem;
    height: 9rem;
    min-width: 8.5rem;
    min-height: 9rem;
    background-position: center;
    background-size: cover;
    border: ${props =>
      props.watchingNow
        ? `0.3rem solid ${Theme.colors.fontcolors.secondary}`
        : "none"};
  }

  @media ${Theme.breakpoints.desktop} {
    margin-bottom: 3rem;
    z-index: 2;

    figure {
      width: 10.5rem;
      height: 12rem;
      min-width: 10.5rem;
      min-height: 12rem;
    }
  }

  /* Fixing the witdh to allow for centering in mobile devices */
  @media ${Theme.breakpoints.notDesktop} {
    width: 35rem;
  }

  /* Narrow the width on mobile landscape to allow for a 2x2 disposition of story CTAs to fit */
  @media ${Theme.breakpoints.mediumDown} {
    width: ${props => (props.isLandscape ? "32rem" : "")};
  }

  @media ${Theme.breakpoints.smallDown} {
    width: ${props => (props.isLandscape ? "28.5rem" : "")};
  }

  /* In the specific case of very small mobile landscape, stack the story CTAs as in portrait mode
     and allow for enough width so story titles don't wrap to the 2nd line */
  @media ${Theme.breakpoints.iPhone5LandscapeDown} {
    width: ${props => (props.isLandscape ? "35rem" : "")};
  }

  @media ${Theme.breakpoints.smallMobileDown} {
    justify-content: left;
  }
`

const CtaInfoStyles = css`
  // 2x padding (2.4) + figure (8.5 + 1.5) + 0.2rem angle-right
  max-width: calc(100vw - 15.5rem);
  justify-content: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  h3 {
    margin: 0.2rem 0 0.4rem 0;
    line-height: 2rem;
    color: white;
  }

  @media ${Theme.breakpoints.notDesktop} {
    h3 {
      font-size: 2rem;
      font-weight: 400;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;
    }
  }

  span {
    display: block;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.2rem;
    line-height: 1.7rem;
    &.duration,
    &.status {
      font-size: 1rem;
    }
    &.status {
      text-transform: uppercase;
    }
    &.time {
      display: inline-block;
      width: 2.3rem;
      margin: 0 0.5rem;
    }
    &.subject {
      display: none;
    }
  }

  @media ${Theme.breakpoints.desktop} {
    width: 21rem;
    max-width: 21rem;

    h3 {
      margin: 1rem 0;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    span {
      font-size: 2rem;
      line-height: 2.1rem;
      &.duration,
      &.status {
        font-size: 1.4rem;
      }
      &.time {
        width: 3rem;
        font-size: 1.2rem;
      }
    }
  }
`

const AngleIconStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-left: 0.8rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.75);
  }
`

const RewatchIconStyles = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  color: white;

  @media ${Theme.breakpoints.notDesktop} {
    font-size: 3rem;
  }
`
