import Hls from "hls.js"

export function isCompatible(): boolean {
  if (typeof window === "undefined") return true
  if (
    document
      .createElement("video")
      .canPlayType("application/vnd.apple.mpegurl") ||
    Hls.isSupported()
  )
    return true
  return false
}
