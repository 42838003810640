import React from "react"
import {useRecoilValue} from "recoil"
import {subtitlesState} from "../atoms"
import Theme from "../styles/theme"
import {styled} from "@linaria/react"
interface Props {
  storyMenuOpen: boolean
  storyInfoShowing?: boolean
  showingCta?: boolean
  isHomepage?: boolean
  isLandscape?: boolean
}

const Subtitles = ({
  storyMenuOpen,
  storyInfoShowing,
  showingCta,
  isHomepage,
  isLandscape,
}: Props): JSX.Element => {
  const subtitles = useRecoilValue(subtitlesState)

  return (
    <SubtitleStyles
      storyMenuOpen={storyMenuOpen}
      storyInfoShowing={storyInfoShowing}
      showingCta={showingCta}
      isHomepage={isHomepage}
      isLandscape={isLandscape}
    >
      {" "}
      {subtitles && subtitles.length > 0 && (
        <div className="subtitles">
          {subtitles.map((subtitle, index) => (
            <div className="subtitle" key={index}>
              {subtitle}
            </div>
          ))}
        </div>
      )}
    </SubtitleStyles>
  )
}

export default Subtitles

const SubtitleStyles = styled.div<{
  storyMenuOpen: boolean
  storyInfoShowing: boolean | undefined
  showingCta: boolean | undefined
  isHomepage: boolean | undefined
  isLandscape: boolean | undefined
}>`
  position: absolute;
  bottom: 19rem;
  transform: translateY(50%);
  width: 100%;
  transition: ${props =>
    props.storyInfoShowing
      ? "0.2s bottom ease-in-out"
      : "0.5s bottom ease-in-out"};

  @media ${Theme.breakpoints.notDesktop} {
    transition-delay: ${props => (props.storyInfoShowing ? "0s" : "0.5s")};
    bottom: ${props =>
      props.storyInfoShowing && props.isLandscape
        ? "16rem"
        : !props.storyInfoShowing && props.isLandscape
        ? "8.2rem"
        : props.showingCta && !props.isLandscape
        ? "22rem"
        : props.storyInfoShowing
        ? "18rem"
        : "12rem"};
  }

  @media ${Theme.breakpoints.smallDown} {
    bottom: ${props =>
      !props.storyInfoShowing && props.isLandscape ? "7.8rem" : ""};
  }

  .subtitles {
    margin: 0 auto;
    padding: 1rem;
    font-family: Poppins;
    font-weight: 500;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: 0;
    text-align: center;
    color: white;
    text-shadow: 0 0 0.1rem rgb(0 0 0), 0 0 0.2rem rgb(0 0 0),
      0 0.2rem 0.4rem rgb(0 0 0 / 20%);
    opacity: ${props => (props.storyMenuOpen ? 0 : 1)};
    transition: ${props =>
      props.storyMenuOpen
        ? "0.1s opacity ease-in-out"
        : "0.3s opacity ease-in-out"};
    transition-delay: ${props => (props.storyMenuOpen ? "0s" : "0.1s")};

    // Mobile subtitles
    @media ${Theme.breakpoints.notDesktop} {
      font-size: 1.6rem;
      line-height: 2rem;
      max-width: 95%;
      transition-delay: ${props => (props.storyMenuOpen ? "0s" : "0.3s")};

      // Handle the specific case of the iPad on portrait mode,
      // for which mobile subtitles are too small.
      @media ${Theme.breakpoints.small} {
        font-size: ${props => (props.isLandscape ? "" : "2.4rem")};
        line-height: ${props => (props.isLandscape ? "" : "3rem")};
      }
    }
  }
`
