import {useCallback, useState} from "react"

type Orientation = "portrait" | "landscape"

// We'll use a mock 'window' for the build phase
// eslint-disable-next-line @typescript-eslint/no-empty-function
const mockWindow = {matches: false, addEventListener: () => {}}

export const useOrientation = (): Orientation => {
  const query =
    typeof window != "undefined"
      ? window.matchMedia("(orientation: portrait)")
      : mockWindow

  const toOrientation = (portrait?: boolean) =>
    portrait ? "portrait" : "landscape"

  const [orientation, setOrientation] = useState<Orientation>(
    toOrientation(query.matches),
  )

  query.addEventListener(
    "change",
    useCallback(event => setOrientation(toOrientation(event.matches)), []),
  )

  return orientation
}
