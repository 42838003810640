import React from "react"
import {css} from "@linaria/core"
import Theme from "../styles/theme"
import "fontsource-poppins/300.css"
import "fontsource-poppins/500.css"
import "fontsource-poppins/600.css"
import {Typography} from "./typography"
import {fontawesome} from "./fontawesome"

export const GlobalStyles = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  return (
    <div
      className={`layout-container ${Typography} ${globalStyles} ${fontawesome}`}
    >
      {children}
    </div>
  )
}

const globalStyles = css`
  :global() {
    html {
      font-size: 10px;
      line-height: 1.15;
    }

    .layout-video body {
      height: 200vh;
      overflow: hidden;

      @media (${Theme.breakpoints.desktopDown}) {
        overflow: hidden;
        position: fixed;
      }
    }

    .menu-open body {
      overflow: hidden;
      position: fixed;
    }

    body {
      height: "auto";
      margin: 0;
      padding: 0;
      overflow: "auto";
      background-color: ${Theme.colors.backgrounds.darkGrey};
      @media (${Theme.breakpoints.desktopDown}) {
        overflow: "auto";
        position: "";
      }
      .header {
        #homepage {
          transition: fill 0.3s ease-in;
          transition-delay: 0.3s;
        }
      }
      p {
        font-size: 2rem;
        font-weight: 200;
      }
    }
    &.contact {
      .layout-container {
        overflow-y: scroll;
        max-height: 100vh;
        background: white;

        .header {
          @media ${Theme.breakpoints.notDesktop} {
            background-color: white;
          }
        }

        #homepage {
          fill: #212121 !important;
        }

        @media ${Theme.breakpoints.notDesktop} {
          .burger {
            span {
              background-color: #212121 !important;
            }
          }
        }

        main {
          display: block;
          background: white;
          padding: 0;
          padding-top: 7rem;

          @media ${Theme.breakpoints.desktop} {
            margin-top: auto;
            padding-top: auto;
          }
        }
      }
    }
    &.privacy-statement {
      .layout-container {
        overflow-y: scroll;
        max-height: 100vh;
        background: white;

        .header {
          @media ${Theme.breakpoints.notDesktop} {
            background-color: white;
          }
        }

        #homepage {
          fill: #212121;
        }

        .burger {
          span {
            background-color: #212121;
          }
        }

        main {
          display: block;
          background: white;
          padding: 0;
          padding-top: 7rem;

          @media ${Theme.breakpoints.desktop} {
            margin-top: auto;
            padding-top: auto;
          }
        }
      }
    }
    &.404 {
      .layout-container {
        .header {
          g#homepage {
            fill: black !important;
          }
          .burger {
            span {
              background: black;
            }
          }
        }
        main {
          display: block;
          background-color: white;
        }
      }
    }
    &.incompatible {
      .layout-container {
        .header {
          background: white;

          g#homepage {
            fill: black !important;
          }

          .burger {
            display: none;
          }
        }

        main {
          display: block;
          position: static;
          background-color: white;
        }
      }
    }

    * {
      font-family: "Poppins";
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      color: #000000;
      text-decoration: none;
    }

    h1 {
      font-size: 5rem;
      font-weight: 500;
      line-height: 3.9rem;
    }

    h2 {
      font-size: 5rem;
      font-weight: 400;
    }

    h3 {
      font-size: 3.5rem;
      font-weight: 200;
      line-height: 2.6rem;
    }

    h4 {
      font-size: 2.7rem;
      font-weight: 200;
      line-height: 4.5rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 3.4rem;
    }

    input[type="submit"],
    .white-button {
      font-size: 1.8rem;
      color: ${Theme.colors.fontcolors.secondary};
      background-color: ${Theme.colors.buttoncolors.light};
      border: 5px solid rgba(151, 151, 151, 0.1);
      padding: 1.7rem;

      &:hover {
        background-color: rgba(151, 151, 151, 0.1);
      }
    }

    .separator {
      background-color: ${Theme.colors.backgrounds.red};
      height: 0.2rem;
      width: 100%;
      max-width: 4.7rem;
    }

    strong {
      font-weight: bolder;
    }
  }
`
