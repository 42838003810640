import React, {useState, useEffect, useRef} from "react"
import {css} from "@linaria/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useRecoilState, useRecoilValue} from "recoil"
import {mouseMovedState, videoRefState, videoTimeState} from "../atoms"
import Theme from "../styles/theme"
import {PulseStyles, PulseStylesAnimation} from "../styles/overlay-styles"
import {ForwardIcon, RewindIcon, IconStyles, PlayIconStyles} from "../icons"
import {videoPausedState} from "../atoms/video"
import {faPause, faPlay} from "@fortawesome/pro-solid-svg-icons"
import {styled} from "@linaria/react"

interface Props {
  active: boolean
  storyMenuOpen: boolean
  watched: boolean
  setInactive: () => void
}

const VideoControls = (props: Props): JSX.Element | null => {
  const videoRef = useRecoilValue(videoRefState)
  const [paused, setPaused] = useRecoilState(videoPausedState)
  const [buttonClicked, setButtonClicked] = useState<string | undefined>()
  const mouseMoved = useRecoilValue(mouseMovedState)
  const [mouseInside, setMouseInside] = useState<boolean>(false)
  const controls = useRef<HTMLDivElement>(null)
  const [initialisedAt] = useState<number>(new Date().getTime())
  const videoTime = useRecoilValue(videoTimeState)

  useEffect(() => {
    if (controls.current) {
      controls.current.addEventListener("mouseenter", () =>
        setMouseInside(true),
      )
      controls.current.addEventListener("mouseleave", () =>
        setMouseInside(false),
      )
    }
  }, [controls])

  useEffect(() => {
    if (buttonClicked) {
      const timeout = setTimeout(() => {
        setButtonClicked(undefined)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [buttonClicked])

  let timeout: NodeJS.Timeout
  useEffect(() => {
    if (props.active) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        props.setInactive()
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [props.active, buttonClicked, paused])

  const showWhenPaused = new Date().getTime() - initialisedAt > 1000

  return (
    <VideoControlsStyles
      active={
        !props.watched &&
        (props.active || (paused && showWhenPaused)) &&
        !props.storyMenuOpen
      }
      desktopActive={!props.watched && (mouseMoved || mouseInside || paused)}
      ref={controls}
    >
      <div
        className={`${PulseStyles} ${
          buttonClicked === "rewind" ? PulseStylesAnimation : ""
        }`}
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.currentTime -= 5
            setButtonClicked("rewind")
          }
        }}
      >
        {RewindIcon}
      </div>

      <div
        className={`${IconStyles} ${PulseStyles} ${
          buttonClicked === "play/pause" ? PulseStylesAnimation : ""
        } ${PlayIconStyles} ${buttonClicked}`}
      >
        <FontAwesomeIcon
          onClick={() => {
            if (videoRef.current) {
              if (videoRef.current.paused) {
                setPaused(false)
                videoRef.current.play()
              } else {
                setPaused(true)
                videoRef.current.pause()
              }
              setButtonClicked("play/pause")
            }
          }}
          icon={paused ? faPlay : faPause}
        />
      </div>

      <div
        className={[
          PulseStyles,
          buttonClicked === "forward" ? PulseStylesAnimation : "",
        ]
          .concat(
            (videoRef.current?.duration || 0) - videoTime <= 7.5
              ? [DisabledStyles]
              : [],
          )
          .join(" ")}
        onClick={() => {
          if (videoRef.current && videoRef.current.duration - videoTime > 7.5) {
            videoRef.current.currentTime += 5
            setButtonClicked("forward")
          }
        }}
      >
        {ForwardIcon}
      </div>
    </VideoControlsStyles>
  )
}

export default VideoControls

const VideoControlsStyles = styled.div<{
  active: boolean
  desktopActive: boolean
}>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50%;
  transition: 0.5s opacity ease-in-out;

  @media ${Theme.breakpoints.notDesktop} {
    top: calc(50vh - 11rem);
    opacity: ${props => (props.active ? "1" : "0")};
    pointer-events: ${props => (props.active ? "all" : "none")};
  }

  @media ${Theme.breakpoints.desktop} {
    width: 20rem;
    height: 6.1rem;
    left: 5.3rem;
    bottom: 6.5rem;
    justify-content: space-between;
    opacity: ${props => (props.desktopActive ? "1" : "0")};
    pointer-events: ${props => (props.desktopActive ? "all" : "none")};
  }
`
const DisabledStyles = css`
  opacity: 0.3;
  pointer-events: none;
`
