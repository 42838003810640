import React from "react"
import Theme from "../styles/theme"
import {styled} from "@linaria/react"

const PageWidth = ({
  children,
  customHeight,
  customMaxHeight,
}: {
  children: React.ReactNode
  customHeight?: number
  customMaxHeight?: number
}): JSX.Element => {
  return (
    <PageWidthStyles
      customHeight={customHeight}
      customMaxHeight={customMaxHeight}
    >
      {children}
    </PageWidthStyles>
  )
}

export default PageWidth

const PageWidthStyles = styled.div<{
  customHeight?: number
  customMaxHeight?: number
}>`
  position: relative;
  /* width: 140rem; */
  /* height: ${props =>
    props.customHeight ? `${props.customHeight}rem` : "auto"}; */
  margin: 0 ${Theme.pagewidth.desktop};
  /* padding: 0rem 6rem 6rem; */
  overflow: "visible";

  @media ${Theme.breakpoints.desktopDown} {
    margin: 0 ${Theme.pagewidth.desktopdown};
    /* padding: 0rem 3rem 6rem; */
    /* max-height: ${props =>
      props.customMaxHeight ? `${props.customMaxHeight}rem` : "auto"}; */
  }
  @media ${Theme.breakpoints.smallDown} {
    margin: 0 ${Theme.pagewidth.mobile};
    /* padding: 0rem 3rem 6rem; */
    /* max-height: ${props =>
      props.customMaxHeight ? `${props.customMaxHeight}rem` : "auto"}; */
  }
  @media ${Theme.breakpoints.largeDesktop} {
    margin: 0 ${Theme.pagewidth.largescreen};
  }
  @media ${Theme.breakpoints.superlargedesktop} {
    margin: 0 ${Theme.pagewidth.superlargedesktop};
  }
`
