import React from "react"

import {Story} from "../types"
import Theme from "../styles/theme"
import {DynamicCallToActionStyles} from "../styles/overlay-styles"
import {styled} from "@linaria/react"

interface Props {
  story?: Story
  visible: boolean
  desktopVisible: boolean
  highlighted: boolean
}

const StoryInfo = (props: Props): JSX.Element => {
  return (
    <StoryInfoStyles
      visible={props.visible}
      desktopVisible={props.desktopVisible}
      className={[
        DynamicCallToActionStyles,
        props.highlighted ? "highlighted" : "",
      ].join(" ")}
    >
      <h3>{props.story?.title}</h3>
      <span className="divider">-</span>
      <span>{props.story?.subjectProject}</span>
    </StoryInfoStyles>
  )
}

export default StoryInfo

const StoryInfoStyles = styled.div<{
  visible: boolean
  desktopVisible: boolean
}>`
  position: relative;
  transition: 0.5s opacity ease-in-out;
  width: 100%;
  height: 100%;
  padding: 2.4rem 2.4rem 0 3rem;
  margin-left: 0;
  user-select: none;

  .divider {
    display: none;
  }

  @media ${Theme.breakpoints.notDesktop} {
    opacity: ${props => (props.visible ? "1" : "0")};

    h3 {
      margin: 0 0 0.3rem 0;
      font-size: 2rem;
      line-height: 3rem;
    }

    span {
      display: inline-block;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.75);
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  @media ${Theme.breakpoints.desktop} {
    opacity: ${props => (props.desktopVisible ? "1" : "0")};
    &.highlighted {
      position: absolute;
      left: 3rem;
      top: 13rem;
      bottom: 13rem;
      height: calc(100% - 26rem);
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      &:after {
        display: none;
      }

      h3 {
        margin: 0 0 0.3rem 0;
        font-size: 7rem;
        line-height: 9rem;
        color: #fff;
      }

      span {
        font-size: 3rem;
        line-height: 4.6rem;
        color: #fff;
        &.person {
          display: none;
        }
      }
    }

    &:not(.highlighted) {
      position: absolute;
      bottom: 6.6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      left: 0;
      right: 0;
      height: unset;
      opacity: ${props => (props.desktopVisible ? "1" : "0")};
      transition: 0.5s opacity ease-in-out;

      h3 {
        font-size: 2rem;
        color: #ffffff;
      }

      span {
        font-size: 2rem;
        color: #acacac;
      }

      .divider {
        display: block;
        margin: 0 0.5rem;
      }
    }
  }
`
