import React, {useState, useEffect} from "react"
import {Link} from "gatsby"
import {useRecoilState, useSetRecoilState} from "recoil"
import {isDesktop} from "react-device-detect"
import {menuState} from "../../atoms"
import Theme from "../../styles/theme"
import {styled} from "@linaria/react"
interface Props {
  path: string
}
const Menu = ({path}: Props): JSX.Element => {
  const [openMenu] = useRecoilState(menuState)
  const setOpenMenu = useSetRecoilState(menuState)
  const [skipTransitions, setSkipTransitions] = useState<boolean>(isDesktop)

  // When changing pages we want the menu to transition on mobile but not on desktop
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSkipTransitions(false)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <MenuStyles
      skipTransitions={skipTransitions}
      className={openMenu ? "open" : ""}
    >
      <ul>
        <li>
          <Link to="/" onClick={() => (path == "/" ? setOpenMenu(false) : "")}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about/"
            onClick={() => (path == "/about/" ? setOpenMenu(false) : "")}
          >
            About
          </Link>
        </li>
        <li>
          <a href="https://jobs.voormedia.com/">Jobs</a>
        </li>
        <li>
          <Link
            to="/contact/"
            onClick={() => (path == "/contact/" ? setOpenMenu(false) : "")}
          >
            Contact
          </Link>
        </li>
      </ul>
    </MenuStyles>
  )
}

export default Menu

const MenuStyles = styled.div<{skipTransitions: boolean}>`
  position: fixed;
  z-index: 10;
  height: 0;
  transition: ${props =>
    props.skipTransitions
      ? "none"
      : "width 0.5s ease-out, background 1s ease-out"};
  background-color: white;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  left: auto;
  right: 0;
  height: 100vh;

  @media ${Theme.breakpoints.desktop} {
    max-width: 40rem;
  }

  &.open {
    height: 100vh;
    transition: width 0.3s ease-in, background 0.5s ease-in;
    transition-delay: 0.1s;
    transform: translateZ(0); // Fix for iOS Safari in fullscreen landscape
    width: 100%;

    li {
      transform: scale(1) translateY(0px);
      opacity: 1;

      &:nth-of-type(1) {
        transition-delay: 0.27s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.34s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.41s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.48s;
      }
    }
  }

  ul {
    position: relative;
    display: block;
    padding: 0px 48px 0;
    list-style: none;
    margin-top: 112px;

    li {
      margin-top: 5px;
      transform: scale(1.15) translateY(-30px);
      opacity: 0;
      transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      &:nth-of-type(1) {
        transition-delay: 0.36s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.43s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.5s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.57s;
      }
      a {
        display: block;
        margin-bottom: 35px;
        position: relative;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
    }
  }
`
