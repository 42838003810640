import React, {useEffect, useState} from "react"
import {css} from "@linaria/core"
import {useRecoilValue, useRecoilState} from "recoil"
import {
  watchedStoriesState,
  homepageWatchedState,
  videoRefState,
  videoTimeState,
} from "../atoms"
import {navigate} from "gatsby"
import {Story} from "../types"
import Theme from "../styles/theme"

import {StoryCta} from "."
import {styled} from "@linaria/react"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentStory?: Story
  stories: Story[]
  isLandscape: boolean
}

const StoryMenu = (props: Props): JSX.Element => {
  const watched = useRecoilValue(watchedStoriesState)
  const videoRef = useRecoilValue(videoRefState)
  const [homepageWatched, setHomepageWatched] = useRecoilState(
    homepageWatchedState,
  )
  const [screenHeight, setScreenHeight] = useState(0)
  const [playingNext, setPlayingNext] = useState<Story | null>(null)

  const currentTime = useRecoilValue(videoTimeState)
  const finished =
    props.currentStory &&
    Math.round(currentTime) >= props.currentStory.video.duration

  const allStoriesWatched = watched.length === props.stories.length

  useEffect(() => {
    setScreenHeight(window.innerHeight)
    window.addEventListener("resize", () => {
      setScreenHeight(window.innerHeight)
    })
  }, [])

  useEffect(() => {
    if (
      watched.filter(story => story.slug === props.currentStory?.slug).length &&
      watched.length !== props.stories.length
    ) {
      props.setOpen(true)

      if (props.currentStory) {
        setPlayingNext(nextStory(props.currentStory))
      } else {
        setPlayingNext(
          props.stories.find(story => story.playOrder === 0) || null,
        )
      }
    }
  }, [watched])

  useEffect(() => {
    if (homepageWatched) {
      props.setOpen(true)
      if (!allStoriesWatched)
        setPlayingNext(
          props.stories.find(story => story.playOrder === 0) || null,
        )
    }
  }, [homepageWatched])

  useEffect(() => {
    if (playingNext) {
      const timeout = setTimeout(() => {
        // We're reusing the same video component across pages so that
        // new videos can keep autoplaying with sound on mobile devices.
        // When navigating to a new video we do have to reset these values as a result.
        setHomepageWatched(false)
        if (videoRef.current) videoRef.current.currentTime = 0

        navigate(`/stories/${playingNext.slug}/`)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [playingNext])

  const nextStory = (current: Story): Story | null => {
    let next: Story | null = props.stories[props.stories.indexOf(current) + 1]
    if (watched.length === props.stories.length) return null
    if (!next) next = props.stories[0]

    if (next && watched.filter(story => story.slug === next?.slug).length) {
      return nextStory(next)
    } else {
      return next
    }
  }

  return (
    <StoryMenuStyles open={props.open} height={screenHeight}>
      <StoryOptionsStyles
        open={props.open}
        height={screenHeight}
        isLandscape={props.isLandscape}
      >
        <div className="scroll-container">
          {props.stories.map(story => (
            <div style={{justifyContent: "left"}}>
              <StoryCta
                key={story.slug}
                story={story}
                hidden={!props.open}
                watchingNow={
                  !finished && story.slug === props.currentStory?.slug
                }
                playingNext={story.slug === playingNext?.slug}
                isLandscape={props.isLandscape}
              />
            </div>
          ))}
        </div>
      </StoryOptionsStyles>

      <a className={ButtonStyles} onClick={() => props.setOpen(!props.open)}>
        {props.open ? "Close" : "See all stories"}
      </a>
    </StoryMenuStyles>
  )
}

export default StoryMenu

const StoryMenuStyles = styled.div<{open: boolean; height: number}>`
  height: ${props =>
    props.open ? `calc(${props.height}px - 6.7rem)` : `7.5rem`};
  transition: height 0.5s ease-in-out;
  transition-delay: ${props => (props.open ? "0.3s" : `0`)};
`

const StoryOptionsStyles = styled.div<{
  open: boolean
  height: number
  isLandscape: boolean
}>`
  height: ${props =>
    props.open ? `calc(${props.height}px - 6.7rem - 7.5rem)` : `0`};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  transition-delay: ${props => (props.open ? "0.3s" : `0`)};

  .scroll-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 75%;
    width: 100%;

    @media ${Theme.breakpoints.largeDesktop} {
      justify-content: space-between;
    }

    /* On mobile devices, stack storyCTAs in portrait mode & display 2x2 on landscape */
    /* TODO: review breakpoints to better take into consideration the orientation of mobile screens */
    @media ${Theme.breakpoints.notDesktop} {
      display: flex;
      align-items: center;
      margin: ${props => (props.isLandscape ? "" : "auto")};
      height: ${props => (props.isLandscape ? "" : "fit-content")};
      width: ${props => (props.isLandscape ? "" : "fit-content")};
      flex-direction: ${props => (props.isLandscape ? "" : "row")};
      flex-wrap: ${props => (props.isLandscape ? "wrap" : "wrap")};
      max-width: ${props => (props.isLandscape ? "" : "70rem")};
      align-items: center;
      /* Trick to compensate for header padding and center story menu between header & footer */
      > a:first-of-type {
        padding-top: 0;
      }
      > a:nth-of-type(2) {
        padding-top: ${props => (props.isLandscape ? "0.9rem" : "")};
      }

      h3 {
        font-size: 2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
      }
    }

    @media ${Theme.breakpoints.iPhone5LandscapeDown} {
      > a:nth-of-type(2) {
        padding-top: ${props => (props.isLandscape ? "0.9rem" : "")};
      }
    }
  }

  @media ${Theme.breakpoints.desktop} {
    display: flex;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    opacity: ${props => (props.open ? "1" : "0")};
    transition: opacity 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out,
      background-color 0.5s ease-in-out;
    backdrop-filter: ${props => (props.open ? "blur(8px)" : "none")};
    background-color: ${props =>
      props.open ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0)"};

    .scroll-container {
      margin: auto;
      height: fit-content;
      width: fit-content;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 96rem;

      > a {
        flex-basis: calc(50% - 10rem);
      }
    }
  }

  @media ${Theme.breakpoints.smallMobileDown} {
    .scroll-container {
      margin-left: 2rem;
    }
  }
`

const ButtonStyles = css`
  display: block;
  padding: 1rem 4rem;
  text-decoration: none;
  box-shadow: 0 0 0px 4px rgb(151 151 151 / 10%);
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin: 2.2rem 9rem 2.2rem 3.3rem;
  pointer-events: all;
  background-color: rgb(255 255 255 / 10%);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;

  @media ${Theme.breakpoints.desktop} {
    position: absolute;
    right: 12rem;
    bottom: 4.5rem;
    width: 18rem;
    height: 5.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 2.2rem;
    line-height: 2.8rem;
    font-size: 1.8rem;
    box-shadow: 0 0 0px 6px rgb(151 151 151 / 10%);
  }

  &:hover {
    background-color: rgb(255 255 255 / 20%);
  }
`
