import {atom} from "recoil"

export const menuState = atom({
  key: "menuOpen",
  default: false,
})

export const mouseMovedState = atom({
  key: "mouseMoved",
  default: false,
})
