import React, {useState, useEffect} from "react"
import {Link} from "gatsby"
import {isDesktop} from "react-device-detect"
import {useRecoilState, useRecoilValue} from "recoil"
import {layoutStylesState, menuState} from "../../atoms"
import Theme from "../../styles/theme"
import {VoormediaLogo} from "../../icons"
import {LayoutStyle} from "../../types"
import {styled} from "@linaria/react"

const Header = (): JSX.Element => {
  const [openMenu, setOpenMenu] = useRecoilState(menuState)
  const [skipTransitions, setSkipTransitions] = useState<boolean>(isDesktop)
  const layoutStyle = useRecoilValue(layoutStylesState)

  // When changing pages we want the burger to transition on mobile but not on desktop
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSkipTransitions(false)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <HeaderStyles
      layoutStyle={layoutStyle}
      openMenu={openMenu}
      skipTransitions={skipTransitions}
    >
      <div className="header">
        <Link to="/" className="logo">
          {VoormediaLogo}
        </Link>
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className={`burger ${openMenu ? "open" : ""}`}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </HeaderStyles>
  )
}

export default Header

const HeaderStyles = styled.div<{
  layoutStyle: LayoutStyle
  openMenu: boolean
  skipTransitions: boolean
}>`
  position: ${props => (props.layoutStyle == "video" ? "fixed" : "relative")};
  z-index: 11;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 3rem;
    margin: 0 auto;

    @media ${Theme.breakpoints.desktop} {
      padding: 3rem 5.5rem;
    }
  }

  svg {
    max-width: 10.3rem;
    @media ${Theme.breakpoints.desktop} {
      max-width: 19.5rem;
    }

    g#homepage {
      fill: ${props =>
        props.openMenu && !props.skipTransitions ? "#212121" : "white"};
      @media ${Theme.breakpoints.desktop} {
        fill: white;
      }
    }
  }

  .burger {
    width: 2.1rem;
    height: 2.1rem;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: ${props => (props.skipTransitions ? "none" : "0.5s")};
    -moz-transition: ${props => (props.skipTransitions ? "none" : "0.5s")};
    -o-transition: ${props => (props.skipTransitions ? "none" : "0.5s")};
    transition: ${props => (props.skipTransitions ? "none" : "0.5s")};
    cursor: pointer;

    &.open {
      position: fixed;
      right: 5.5rem;
      @media (${Theme.breakpoints.desktopDown}) {
        right: 3rem;
      }
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: ${props => (!props.openMenu ? "#FFFFFF" : "#212121")};
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: ${props =>
        props.openMenu
          ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
          : props.skipTransitions
          ? "none"
          : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      -moz-transition: ${props =>
        props.openMenu
          ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
          : props.skipTransitions
          ? "none"
          : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      -o-transition: ${props =>
        props.openMenu
          ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
          : props.skipTransitions
          ? "none"
          : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
      transition: ${props =>
        props.openMenu
          ? "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out"
          : props.skipTransitions
          ? "none"
          : "top 0.25s ease-in-out, width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.4s"};
    }

    span:nth-of-type(1) {
      top: 0px;
    }

    span:nth-of-type(2),
    span:nth-of-type(3) {
      top: 7px;
    }

    span:nth-of-type(4) {
      top: 14px;
    }

    &.open span:nth-of-type(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-of-type(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.open span:nth-of-type(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &.open span:nth-of-type(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  }
`
