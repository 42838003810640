import {atom} from "recoil"
import {LayoutStyle, Seo} from "../types"

export const pageTitleState = atom({
  key: "pageTitle",
  default: "Voormedia",
})

export const modalVideo = atom({
  key: "modalVideo",
  default: false,
})

export const seoState = atom<Seo | undefined>({
  key: "seoContent",
  default: undefined,
})

export const layoutStylesState = atom<LayoutStyle>({
  key: "layoutStyle",
  default: "",
})
