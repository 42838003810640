import {css} from "@linaria/core"

export const Typography = css`
  /* h1 {
    font-size: 5rem;
    font-weight: 500;
    line-height: 3.9rem;
  }

  h3 {
    font-size: 3.5rem;
    font-weight: 200;
    line-height: 2.6rem;
  }

  h4 {
    font-size: 2.7rem;
    font-weight: 200;
    line-height: 4.5rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 3.4rem;
  } */
`
