import React, {useEffect} from "react"
import {css} from "@linaria/core"
import {Helmet} from "react-helmet"
import {useRecoilValue, useSetRecoilState} from "recoil"
import {Seo} from "../../types"
import {
  seoState,
  pageTitleState,
  layoutStylesState,
  videoTimeState,
  menuState,
} from "../../atoms"
import {Header, Menu} from ".."

import {GlobalStyles} from "../../styles/global-styles"
import "fontsource-poppins"
import Footer from "./footer"

interface Props {
  title?: string
  path: string
  seoSource?: Seo
  children?: React.ReactNode
}

const Layout = (props: Props): JSX.Element => {
  const seo = useRecoilValue(seoState)
  const pageTitle = useRecoilValue(pageTitleState)
  const layoutStyle = useRecoilValue(layoutStylesState)
  const setVideoTime = useSetRecoilState(videoTimeState)
  const setOpenMenu = useSetRecoilState(menuState)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setOpenMenu(false)
    setVideoTime(0)
  }, [pageTitle])

  return (
    <GlobalStyles>
      <Helmet
        bodyAttributes={{
          class: props.path.split("/")[1] || "home",
        }}
      >
        <title>{pageTitle}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta
          content={(seo || props.seoSource)?.description}
          name="description"
        />
        <meta
          content={(seo || props.seoSource)?.title}
          name="twitter:title"
          property="og:title"
        />
        <meta
          content={(seo || props.seoSource)?.description}
          name="twitter:description"
          property="og:description"
        />
        <meta
          content={(seo || props.seoSource)?.image?.url}
          name="twitter:image:src"
          property="og:image"
        />
        <meta content="website" property="og:type" />
        <meta content="https://voormedia.com/" property="og:url" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="@voormedia" name="twitter:site" />
        <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
        <meta http-Equiv="Cache-Control" content="no-cache" />
        <meta http-Equiv="Pragma" content="no-cache" />
        <meta http-Equiv="Expires" content="0" />
      </Helmet>

      <Menu key={`${layoutStyle}-menu`} path={props.path} />
      <Header key={`${layoutStyle}-header`} />
      <main className={`${MainStyles} main-container`}>{props.children}</main>
      {props.path === "/about/" && <Footer />}
    </GlobalStyles>
  )
}

export default Layout

const MainStyles = css`
  display: block;
`
